<template>
  <div class="button-container">
    <a
      target="_blank"
      :href="buttonUrl"
      class="button"
      @mouseover="applyHoverStyles"
      @mouseleave="removeHoverStyles"
    >
      <slot>Default Text</slot>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    buttonUrl: {
      type: String,
      default: "#",
    },
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px 4vh;
}

.button {
  padding: 15px 50px;
  font-size: 22px;
  font-family: "satoshi";
  text-decoration: none;
  border: none;
  color: #fffbf0eb;
  background-color: #191919;
  border-radius: 222px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: #131313;
  color: #fffbf0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 870px) {
  .button-container {
    margin: 1vh 0 3vh;
  }
  .button {
    font-size: 18px;
    padding: 10px 35px;
  }
}
</style>
