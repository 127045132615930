<template>
  <div id="accordionProjects" class="accordion-container">
    <div class="accordion-title">
      <h1 :style="{ color: accordionTitleColor }">
        {{ props.accordionTitle }}
      </h1>
    </div>
    <div class="accordion-content-container">
      <div class="accordion-links-container">
        <div
          :style="{ borderColor: accordionBorderColor }"
          class="card"
          v-for="item in items"
          :key="item.id"
        >
          <div class="card-header" @click.prevent="toggleExpand(item)">
            <span :style="{ color: accordionItemColor }">{{ item.title }}</span>
            <div class="icon">
              <div class="category-arrow">
                <svg
                  :class="{ 'rotate-45': item.isExpand }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    :fill="accordionArrowColor"
                    d="M16.249 4.35547L16.2383 15.1621C16.2383 15.7744 15.8408 16.1934 15.1963 16.1934H4.38965C3.78809 16.1934 3.37988 15.7422 3.37988 15.2266C3.37988 14.7109 3.8418 14.2705 4.34668 14.2705H8.08496L13.3271 14.4424L11.3291 12.6807L0.683594 2.02441C0.490234 1.83105 0.382812 1.57324 0.382812 1.34766C0.382812 0.832031 0.844727 0.337891 1.38184 0.337891C1.62891 0.337891 1.86523 0.43457 2.05859 0.62793L12.7256 11.2949L14.498 13.293L14.3047 8.27637V4.3125C14.3047 3.79688 14.7451 3.33496 15.2715 3.33496C15.7871 3.33496 16.249 3.76465 16.249 4.35547Z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div
            class="card-body"
            :ref="'content' + item.id"
            :style="[item.isExpand ? { height: item.computedHeight } : {}]"
          >
            <hr />

            <div
              class="card-content"
              v-for="(content, index) in item.content"
              :key="index"
              @mouseover="hoveredContentId = content.route"
            >
              <router-link
                :to="content.route"
                :style="{ color: cardContentLinkColor }"
              >
                {{ content.text }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="accordion-img"
        :style="[
          accordionImgStyle,
          { backgroundImage: 'url(' + getBackgroundImage() + ')' },
        ]"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps({
  accordionTitle: {
    type: String,
    default: "Autres projets",
  },
  accordionTitleColor: {
    type: String,
    default: "#191919",
  },
  accordionItemColor: {
    type: String,
    default: "#191919",
  },
  accordionArrowColor: {
    type: String,
    default: "#191919",
  },
  accordionBorderColor: {
    type: String,
    default: "#191919",
  },
  accordionLinkColor: {
    type: String,
    default: "#191919",
  },
  cardContentLinkColor: {
    type: String,
    default: "#191919",
  },
});
</script>

<script>
import leamoImage from "@/assets/img/img-project-leamo.webp";
import botabotaImage from "@/assets/img/03-project-botabota.webp";
import eikonicaImage from "@/assets/img/img-project-eikonica.webp";
import audaImage from "@/assets/img/img-project-auda.webp";
import gejyImage from "@/assets/img/img-project-gegy.webp";
import isometricImage from "@/assets/img/img-project-isometric.webp";

export default {
  computed: {
    accordionImgStyle() {
      return this.hoveredContentId
        ? { opacity: 1, transition: "opacity 0.5s ease-in-out" }
        : { opacity: 0, transition: "opacity 0.5s ease-out" };
    },
  },
  data() {
    return {
      hoveredContentId: null,
      items: [
        {
          id: 1,
          title: "UI Design",
          content: [
            { text: "Botabota", route: "/botabota" },
            { text: "eikonica", route: "/eikonica" },
            { text: "leamo", route: "/leamo" },
          ],
          isExpand: false,
          computedHeight: 0,
        },
        {
          id: 2,
          title: "Fronted Development",
          content: [
            { text: "eikonica", route: "/eikonica" },
            { text: "echoes of auda", route: "/auda" },
            { text: "leamo", route: "/leamo" },
          ],
          isExpand: false,
          computedHeight: 0,
        },
        {
          id: 3,
          title: "Illustration",
          content: [{ text: "echoes of auda", route: "/auda" }],
          isExpand: false,
          computedHeight: 0,
        },
        {
          id: 4,
          title: "Graphisme",
          content: [
            { text: "Gejyjiji", route: "/gejy" },
            { text: "isometric game", route: "/isometric" },
            { text: "Botabota", route: "/botabota" },
          ],
          isExpand: false,
          computedHeight: 0,
        },
      ],
    };
  },
  methods: {
    getBackgroundImage() {
      // Renvoie l'image basée sur l'ID survolé
      switch (this.hoveredContentId) {
        case "/leamo":
          return leamoImage;
        case "/botabota":
          return botabotaImage;
        case "/eikonica":
          return eikonicaImage;
        case "/auda":
          return audaImage;
        case "/gejy":
          return gejyImage;
        case "/isometric":
          return isometricImage;
        default:
          return null; // ou une image par défaut si aucun survol
      }
    },
    toggleExpand(item) {
      item.isExpand = !item.isExpand;
    },
    getComputedHeight() {
      this.items.forEach((item) => {
        var content = this.$refs["content" + item.id][0];

        content.style.height = "auto";
        content.style.position = "absolute";
        content.style.visibility = "hidden";
        content.style.display = "block";

        var height = getComputedStyle(content).height;
        item.computedHeight = height;

        content.style.height = 0;
        content.style.position = null;
        content.style.visibility = null;
        content.style.display = null;
      });
    },
  },
  mounted() {
    this.getComputedHeight();
  },
};
</script>

<style scoped>
.accordion-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2vh 0 5vh;
}

.accordion-content-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-end;
  align-items: flex-end;
  z-index: 1;
  padding: 10px;
  font-family: "Satoshi";
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  position: relative;
  bottom: 14vh;
}

.accordion-title {
  width: 36%;
}

svg {
  transition: transform 0.3s ease-in-out;
}

.rotate-45 {
  transform: rotate(45deg);
}

.card {
  width: 30vw;
  margin: 8px 0;
  border-bottom: #191919 solid 1.5px;
}

.card-header,
.card-content {
  margin: 7px 0;
}

.card-header {
  padding: 22px 0 2px;
  cursor: pointer;
}

.card-content {
  font-weight: 400;
  font-family: "Fafo";
  text-transform: uppercase;
  padding: 10px 2px;
  width: max-content;
}

.card-body {
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease-out 0.1s;
}

.icon {
  float: right;
}

.accordion-img {
  width: 45%;
  border-radius: 15px;
  aspect-ratio: 1/1;
  align-content: flex-end;
  background-size: cover;
  background-position: top center;
  position: relative;
  bottom: 30%;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

@media (max-width: 870px) {
  .accordion-container {
    padding: 6vh 4vw;
  }

  .accordion-title h1 {
    padding-bottom: 0;
    padding-top: 0;
  }

  .card-content {
    padding: 5px;
  }

  .accordion-content-container {
    width: 100%;
    bottom: 0;
  }

  .card,
  .accordion-links-container,
  .accordion-title {
    width: 100%;
  }

  .card-content a {
    font-size: 20px;
  }
  .accordion-img {
    display: none;
  }
}
</style>
