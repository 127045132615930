<template>
  <h3 :style="{ color: skillsTitleColor }" class="skills-title">
    {{ skillsH3 }}
  </h3>
  <h1 class="skills-title">{{ skillsH1 }}</h1>
  <div class="skills-component-container">
    <div class="skills-content-container skills-ui-container">
      <div class="skills-img skills-ui-img">
        <img :src="skillsImgFirst" />
      </div>
      <h2 v-if="showText">UI DESIGN</h2>
    </div>
    <div class="skills-content-container skills-frontend-container">
      <div class="skills-img skills-frontend-img">
        <img :src="skillsImgSecond" />
      </div>
      <h2 v-if="showText">FRONTEND DÉVELOPPEMENT</h2>
    </div>
    <div class="skills-content-container skills-illu-container">
      <div class="skills-img skills-illu-img">
        <img :src="skillsImgThird" />
      </div>
      <h2 v-if="showText">ILLUSTRATION</h2>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  props: {
    skillsTitleColor: {
      type: String,
      default: "#191919",
    },
    skillsH3: {
      type: String,
      default: "",
    },
    skillsH1: {
      type: String,
      default: "",
    },
    showText: {
      type: Boolean,
      default: true,
    },
    skillsImgFirst: {
      type: String,
      default: "",
    },
    skillsImgSecond: {
      type: String,
      default: "",
    },
    skillsImgThird: {
      type: String,
      default: "",
    },
  },
  components: {},
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.skillsAnimation();
  },
  methods: {
    skillsAnimation() {
      gsap.fromTo(
        ".skills-content-container",
        { y: 100, opacity: 0 },
        {
          scrollTrigger: {
            trigger: ".skills-component-container",
            start: "20% bottom",
            end: "bottom 35%",
            scrub: true,
          },
          y: 0,
          opacity: 1,
          stagger: 1,
          duration: 4.5,
          ease: "power3.out",
        }
      );
    },
  },
};
</script>

<style scoped>
.skills-component-container {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5vh;
}

.skills-content-container {
  width: 25%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.skills-img {
  width: 100%;
  height: 80%;
  border-radius: 10px;
}

.skills-img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

h2 {
  padding-top: 20px;
}

.skills-title {
  text-align: center;
  padding: 2vh 0 0 0;
}

@media (max-width: 870px) {
  .skills-component-container {
    height: 70vh;
    flex-direction: column;
    margin-top: 0vh;
    padding: 0 4%;
  }

  .skills-content-container {
    width: 100%;
    height: 30%;
    flex-direction: row;
    position: relative;
    align-items: flex-start;
    text-align: left;
    right: 2.5%;
  }

  .skills-content-container h2 {
    width: 50%;
  }

  .skills-img {
    width: 50%;
    height: 100%;
  }

  .skills-frontend-container {
    flex-direction: row-reverse;
  }

  h2 {
    font-size: 22px;
    padding-top: 25px;
  }
}
</style>
