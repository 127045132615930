<template>
  <div id="contact" class="footer-container">
    <div class="footer-content-container">
      <div class="footer-text-container">
        <div class="footer-links-container">
          <h3>Rubriques</h3>

          <router-link to="/">Accueil</router-link>
          <router-link to="/about">À propos</router-link>
          <a @click="navigateToHash('accordionProjects')">Projets</a>
        </div>
        <div class="footer-contact-container">
          <h3>Me retrouver</h3>
          <a target="_blank" href="https://www.behance.net/alyssatomala"
            >Behance</a
          >
          <a
            target="_blank"
            href="https://www.linkedin.com/in/alyssa-tomala-187841275/"
            >Linkedin</a
          >
          <a href="mailto:alyssa20192004@gmail.com">alyssa20192004@gmail.com</a>
          <a href="@/assets/CV-AlyssaTomala.pdf" download="CV-AlyssaTomala.pdf"
            >Mon CV</a
          >
        </div>
      </div>
      <div class="footer-img-copyright-container">
        <div class="footer-img-tree-container"></div>
        <div class="footer-copyright-container">
          <p>© Alyssa Tomala</p>
        </div>
      </div>
    </div>
    <div class="footer-img-owl-container"></div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  components: {},

  mounted() {
    gsap.registerPlugin(ScrollTrigger);
  },
  methods: {
    navigateToHash(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.footer-container {
  width: 120%;
  height: 48vh;
  position: relative;
  left: -10%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  background-color: #191919f7;
  box-shadow: 0px 0px 10px 0px #00000050;
}
.footer-img-owl-container {
  width: 40%;
  height: 132%;
  position: relative;
  bottom: 32%;
  background-image: url("@/assets/img/footer-img-owl.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom -2vh;
}
.footer-content-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-text-container {
  width: 72%;
  height: 50%;
  margin: 2vh 12%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-contact-container,
.footer-links-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.footer-img-copyright-container {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
}

.footer-img-copyright-container p {
  font-size: 10px;
}

.footer-img-tree-container {
  width: 70%;
  height: 100%;
  background-image: url("@/assets/img/img-footer-tree.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.footer-copyright-container {
  width: 30%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: flex-end;
  justify-content: center;
  padding: 3vh;
}

h3,
p,
a {
  color: #fefdf3;
}

p {
  font-family: "fafo";
}

a {
  font-family: "Satoshi";
  font-size: 22px;
  padding: 8px 0;
  opacity: 0.8;
}

a:hover {
  opacity: 1;
}
h3 {
  padding: 20px 0;
}

@media (max-width: 870px) {
  .footer-container {
    height: 80vh;
  }

  .footer-img-owl-container {
    display: none;
  }

  .footer-content-container {
    width: 100%;
    height: 100%;
    align-items: center;
  }
  .footer-text-container {
    width: 80%;
    flex-direction: column;
  }
  .footer-contact-container,
  .footer-links-container {
    width: 100%;
  }

  .footer-img-copyright-container {
    width: 100%;
    height: 40%;
    display: flex;

    flex-direction: column-reverse;
  }

  .footer-copyright-container {
    width: 40%;
    height: auto;
    margin-bottom: 10%;
    margin-left: 6%;
  }

  .footer-img-tree-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(1) scaleX(-1);
  }
}
</style>
