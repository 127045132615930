<script>
import graphicNav from "@/composants/projectNavComponent.vue";

import graphicPageFooter from "@/composants/footerComponent.vue";
import graphicIntro from "@/composants/projectIntroComponent.vue";
import graphicIntroImg from "@/assets/img/img-project-isometric.webp";

import graphicOneImgSketch from "@/composants/projectOneImgComponent.vue";
import graphicOneSketch from "@/assets/img/img-project-isometric-01.webp";

import graphicOneImgResult from "@/composants/projectOneImgComponent.vue";
import graphicOneResult from "@/assets/img/img-project-isometric-02.webp";

import graphicAccordion from "@/composants/accordionComponent.vue";

export default {
  components: {
    graphicNav,
    graphicPageFooter,
    graphicIntro,
    graphicOneImgSketch,
    graphicOneImgResult,
    graphicAccordion,
  },

  data() {
    return {
      graphicIntroImg: graphicIntroImg,
      graphicOneSketch: graphicOneSketch,
      graphicOneResult: graphicOneResult,
    };
  },
};
</script>
<template>
  <graphicNav />
  <graphicIntro
    projectInfoH1="Isometric game"
    projectInfoH2="Graphisme | 3D"
    projectInfoText="Simple exercice de modélisation 3D où j’ai été mandatée 
    pour créer une parcelle de ville. 
    Ce travail a servi d’élément dans un jeu vidéo où les joueurs doivent naviguer dans une ville assemblée à partir de ce projet et d'autres, pour y découvrir des objets cachés."
    projectInfoTextAbout="Ce projet a été fait dans le cadre de mon apprentissage à eikon en 3ième année."
    :projectIntroImg="graphicIntroImg"
  />

  <graphicOneImgSketch
    projectOneImgH3="Croquis"
    :projectOneImg="graphicOneSketch"
    projectOneBoxShadow="none"
    :showButton="false"
  />

  <graphicOneImgResult
    projectOneImgH3="Résultat final"
    :projectOneImg="graphicOneResult"
    projectOneBoxShadow="none"
    :showButton="false"
  />

  <graphicAccordion />
  <graphicPageFooter />
</template>

<script></script>

<style scoped>
@media (max-width: 870px) {
}
</style>
