<script>
import landingPageHeader from "@/composants/landingPageHeader.vue";
import landingPageShowcaseDesktop from "@/composants/landingPageShowcaseDesktop.vue";
import landingPageShowcaseMobile from "@/composants/landingPageShowcaseMobile.vue";
import landingPageSkills from "@/composants/projectThreeImgComponent.vue";
import landingSkillsImgFirst from "@/assets/img/img-skills-01.webp";
import landingSkillsImgSecond from "@/assets/img/img-skills-02.webp";
import landingSkillsImgThird from "@/assets/img/img-skills-03.webp";

import landingPageAccordion from "@/composants/accordionComponent.vue";
import landingPageFooter from "@/composants/footerComponent.vue";
export default {
  components: {
    landingPageHeader,
    landingPageShowcaseDesktop,
    landingPageShowcaseMobile,
    landingPageSkills,
    landingPageAccordion,
    landingPageFooter,
  },

  data() {
    return {
      landingSkillsImgFirst: landingSkillsImgFirst,
      landingSkillsImgSecond: landingSkillsImgSecond,
      landingSkillsImgThird: landingSkillsImgThird,
    };
  },
};
</script>
<template>
  <landingPageHeader id="landingPage" />
  <landingPageShowcaseDesktop class="landing-page-desktop-showcase" />
  <landingPageShowcaseMobile class="landing-page-mobile-showcase" />
  <div class="landing-page-skills-container">
    <landingPageSkills
      id="landingSkills"
      skillsH1="Mes compétences"
      :skillsImgFirst="landingSkillsImgFirst"
      :skillsImgSecond="landingSkillsImgSecond"
      :skillsImgThird="landingSkillsImgThird"
    />
  </div>
  <landingPageAccordion class="" accordionTitle="Tous les projets" />
  <landingPageFooter class="" />
</template>

<script></script>

<style scoped>
.landing-page-title {
  text-align: center;
}

.landing-page-desktop-showcase {
  display: block;
}

.landing-page-mobile-showcase {
  display: none;
}

.landing-page-skills-container {
  margin: 10vh 0;
}

@media (max-width: 870px) {
  .landing-page-desktop-showcase {
    display: none;
  }
  .landing-page-mobile-showcase {
    display: block;
  }

  .landing-page-title {
    text-align: left;
    padding-left: 4%;
  }

  .landing-page-skills-container {
    margin: 5vh 0;
  }

  .dekstop {
    display: none;
  }
}
</style>
