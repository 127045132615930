<template>
  <div class="project-one-container">
    <div class="project-one-title-container">
      <h3>{{ projectOneImgH3 }}</h3>
    </div>
    <div class="project-one-button-container" v-if="showButton">
      <projectOneButton
        :buttonUrl="buttonLink"
        :buttonColor="buttonColor"
        :buttonBgColor="buttonBgColor"
        :buttonHoverColor="buttonHoverColor"
        :buttonHoverBgColor="buttonHoverBgColor"
      >
        {{ buttonText }}
      </projectOneButton>
    </div>
    <div class="project-one-content-container">
      <div class="project-one-img-container">
        <img
          :src="projectOneImg"
          :style="{ boxShadow: projectOneBoxShadow }"
          alt="Project Image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import projectOneButton from "@/composants/buttonComponent.vue";

export default {
  components: {
    projectOneButton,
  },
  props: {
    projectOneImgH3: {
      type: String,
      default: "Titre",
    },
    projectOneImg: {
      type: String,
      default: "",
    },
    projectOneBoxShadow: {
      type: String,
      default: "0px 0px 10px 2px rgba(0, 0, 0, 0.1)",
    },
    buttonLink: {
      type: String,
      default: "/",
    },
    buttonText: {
      type: String,
      default: "Voir le projet",
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.project-one-container {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 10vh 0;
}

.project-one-content-container {
  width: 100%;
  height: 100%;
}

.project-one-img-container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
@media (max-width: 870px) {
  .project-one-container {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 3.5vh 0;
    padding: 0 2%;
  }
}
</style>
