<template>
  <div class="project-two-container">
    <div class="project-two-title-container">
      <h3>{{ projectTwoImgH3 }}</h3>
    </div>
    <div class="project-two-content-container">
      <div class="project-two-img-container project-two-right-img-container">
        <img :src="projectTwoImgLeft" alt="Project Image" />
      </div>
      <div class="project-two-img-container project-two-left-img-container">
        <img :src="projectTwoImgRight" alt="Project Image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projectTwoImgH3: {
      type: String,
      default: "Titre",
    },
    projectTwoImgLeft: {
      type: String,
      default: "",
    },
    projectTwoImgRight: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.project-two-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  margin: 10vh 0;
}

.project-two-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.project-two-img-container {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 10px;
}

.project-two-img-container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
@media (max-width: 870px) {
  .project-two-container {
    margin: 2vh 0;
    text-align: center;
  }
  .project-two-img-container img {
    border-radius: 4px;
  }

  .project-two-content-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    padding: 0;
  }
}
</style>
