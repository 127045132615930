<template>
  <div class="project-nav-container">
    <router-link to="/"> Accueil </router-link>
    <router-link to="/about"> À propos </router-link>
    <a href="#accordionProjects"><p>Projets</p></a>
  </div>
</template>
<script></script>
<style>
.project-nav-container {
  height: 6vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 0 4%;
  background: rgba(254, 253, 243, 0.2);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(5px);
}

.project-nav-container p,
.project-nav-container a {
  font-family: "Satoshi";
  text-decoration: none;
  font-size: 22px;
  color: #191919;
  margin: 0 40px;
}

@media (max-width: 870px) {
  .project-nav-container {
    justify-content: space-between;
  }

  .project-nav-container p,
  .project-nav-container a {
    margin: 0 20px;
    font-size: 16px;
  }
}
</style>
