<template>
  <div class="button-container">
    <div class="button-showcase-arrow-container">
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.62744 0.162837L30.688 0.162837C32.2041 0.162837 33.2368 1.17358 33.2368 2.73364L33.2368 24.7942C33.2368 26.2664 32.1382 27.2991 30.8418 27.2991C29.5674 27.2991 28.5127 26.1785 28.5127 24.8601V17.0378L28.8203 7.56762L25.3047 11.5666L4.34277 32.5066C3.8374 33.012 3.22217 33.2756 2.60693 33.2756C1.33252 33.2756 0.145996 32.1111 0.145996 30.7927C0.145996 30.1775 0.387695 29.5842 0.893066 29.0789L21.855 8.11694L25.854 4.55737L16.7793 4.9309H8.56152C7.24316 4.9309 6.12256 3.87622 6.12256 2.55786C6.12256 1.26147 7.19922 0.162837 8.62744 0.162837Z"
          fill="#3D3D3D"
        />
      </svg>
    </div>
    <div class="button-star-background-container">
      <svg
        width="108"
        height="108"
        viewBox="0 0 108 108"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M91.6311 18.4557C96.379 18.3785 99.8244 22.9506 98.4415 27.4933L94.7506 39.6175C93.9858 42.13 94.6916 44.8591 96.5785 46.6857L105.685 55.5004C109.096 58.8031 108.3 64.4723 104.11 66.7067L92.9267 72.6699C90.6093 73.9056 89.1786 76.3345 89.2213 78.9604L89.4273 91.6323C89.5045 96.3802 84.9324 99.8256 80.3897 98.4427L68.2655 94.7518C65.753 93.9869 63.0239 94.6928 61.1973 96.5797L52.3826 105.686C49.0799 109.098 43.4107 108.301 41.1763 104.111L35.2131 92.9279C33.9774 90.6105 31.5485 89.1798 28.9226 89.2225L16.2507 89.4285C11.5028 89.5057 8.05741 84.9336 9.44032 80.3909L13.1312 68.2666C13.8961 65.7542 13.1902 63.0251 11.3033 61.1985L2.1972 52.3838C-1.21467 49.0811 -0.417908 43.4119 3.77214 41.1775L14.9551 35.2143C17.2725 33.9786 18.7032 31.5497 18.6605 28.9238L18.4545 16.2519C18.3773 11.504 22.9494 8.0586 27.4921 9.44151L39.6164 13.1324C42.1288 13.8972 44.8579 13.1914 46.6845 11.3045L55.4992 2.19839C58.8019 -1.21348 64.4711 -0.416718 66.7055 3.77333L72.6687 14.9563C73.9044 17.2737 76.3333 18.7044 78.9592 18.6617L91.6311 18.4557Z"
          fill="#FEFDF3"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0%;
  height: 0%;
  padding-top: 80vh;
  padding-left: 16%;
  scale: 1.1;
}

.button-showcase-arrow-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: transform 0.4s ease;
}

.showcase-project-container:hover .button-showcase-arrow-container {
  transform: rotate(45deg);
}

.button-star-background-container {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
