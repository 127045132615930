<template>
  <div class="showcase-container">
    <div class="showcase-introduction-container">
      <div class="showcase-text showcase-text-zero">
        <h1 class="showcase-introduction-title">Sélection de projets</h1>
        <div class="showcase-project-title">
          <p>
            Ceux-ci représentent mon univers, ma passion et mettent en lumière
            la formation que j’aie suivie.
          </p>
        </div>
      </div>
    </div>
    <div class="showcase-text showcase-text-one">
      <div class="showcase-project-title">
        <h1>Echoes of Auda</h1>
        <h5>Frontend Development | UI Design | Illustration</h5>
      </div>
      <div>
        <router-link to="/auda">
          <div class="showcase-project-container img-one"></div>
        </router-link>
        <p>
          C'est un site de promotion immersif pour le DLC du jeu
          plate-forme/aventure «Echoes of Auda».
        </p>
      </div>
    </div>
    <div class="showcase-text showcase-text-two">
      <div class="showcase-project-title">
        <h1>Eikonica</h1>
        <h5>Frontend Development | UI Design | Illustration</h5>
      </div>
      <router-link to="/eikonica">
        <div class="showcase-project-container img-two"></div>
      </router-link>
      <div>
        <p>
          Eikonica est un festival d'art numérique fictif qui se déroule à
          eikon.
        </p>
      </div>
    </div>
    <div class="showcase-text showcase-text-three">
      <div class="showcase-project-title">
        <h1>BotaBota</h1>
        <h5>UI Design | Graphisme</h5>
      </div>
      <router-link to="/botabota">
        <div class="showcase-project-container img-three"></div>
      </router-link>
      <div>
        <p>Botabota représente le jardin botanique de la ville de Fribourg.</p>
      </div>
    </div>

    <div class="showcase-projects-container"></div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  components: {},

  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.headerUpAnimationMobile();
  },
  methods: {
    headerUpAnimationMobile() {
      gsap.to(
        ".header-background-container",

        {
          scrollTrigger: {
            trigger: ".showcase-container",
            start: "-180vh 20%",
            end: "top top",
            scrub: true,
          },
          y: "4vh",
        }
      );
    },
  },
};
</script>

<style scoped>
.showcase-container {
  width: 100%;
}
.showcase-introduction-container {
  height: 20%;
}
.showcase-project-container {
  height: 18vh;
  width: 100%;
  position: relative;

  background-size: cover;
  background-position: center bottom 28%;
  background-repeat: no-repeat;
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
}
.img-one {
  background-image: url("../assets/img/01-img-project-auda.webp");
}

.img-two {
  background-image: url("../assets/img/02-project-eikonica.webp");
}
.img-three {
  background-image: url("../assets/img/03-project-botabota.webp");
}

.showcase-text {
  padding: 3vh 10px;
  opacity: 1;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.showcase-project-title {
  padding-bottom: 20px;
}

p {
  padding-top: 10px;
}

h1 {
  padding: 0 0 2px 0;
}

@media (max-width: 870px) {
}
</style>
