<template>
  <div class="project-intro-container">
    <div
      class="project-intro-content-img-container project-intro-content-container"
    >
      <div class="project-intro-title-container">
        <h1>{{ projectInfoH1 }}</h1>
        <h2>{{ projectInfoH2 }}</h2>
      </div>
      <div
        class="project-intro-content-img-container project-intro-img-container-mobile"
      >
        <div class="project-intro-star-border-container">
          <svg
            width="1083"
            height="1083"
            viewBox="0 0 1083 1083"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M541.5 0C242.438 0 0 242.438 0 541.5C0 840.562 242.438 1083 541.5 1083C840.562 1083 1083 840.562 1083 541.5C1083 242.438 840.562 0 541.5 0ZM1040.88 559.53C1052.49 550.727 1052.49 533.273 1040.88 524.47L889.841 409.944C883.506 405.14 880.256 397.293 881.339 389.417L907.158 201.634C909.142 187.199 896.801 174.858 882.366 176.842L694.583 202.661C686.707 203.744 678.86 200.494 674.056 194.159L559.53 43.1194C550.727 31.5093 533.273 31.5093 524.47 43.1194L409.944 194.159C405.14 200.494 397.293 203.744 389.417 202.661L201.634 176.842C187.199 174.858 174.858 187.199 176.842 201.634L202.661 389.417C203.744 397.293 200.494 405.14 194.159 409.944L43.1194 524.47C31.5092 533.273 31.5093 550.727 43.1194 559.53L194.159 674.056C200.494 678.86 203.744 686.707 202.661 694.583L176.842 882.366C174.858 896.801 187.199 909.142 201.634 907.158L389.417 881.339C397.293 880.256 405.14 883.506 409.944 889.841L524.47 1040.88C533.273 1052.49 550.727 1052.49 559.53 1040.88L674.056 889.841C678.86 883.506 686.707 880.256 694.583 881.339L882.366 907.158C896.801 909.142 909.142 896.801 907.158 882.366L881.339 694.583C880.256 686.707 883.506 678.86 889.841 674.056L1040.88 559.53Z"
              fill="#FEFDF3"
            />
          </svg>
        </div>
        <img :src="projectIntroImg" alt="Project Image" />
      </div>
      <div class="project-intro-text-container">
        <p v-html="formattedProjectInfoText"></p>
        <p
          v-html="formattedProjectInfoTextAbout"
          class="project-intro-text-about"
        ></p>
      </div>
    </div>
    <div
      class="project-intro-content-img-container project-intro-img-container-desktop"
    >
      <img :src="projectIntroImg" alt="Project Image" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projectInfoH1: {
      type: String,
      default: "Titre",
    },
    projectInfoH2: {
      type: String,
      default: "Sous-titre",
    },
    projectInfoText: {
      type: String,
      default: "Texte",
    },
    projectInfoTextAbout: {
      type: String,
      default: "Texte information",
    },
    projectIntroImg: {
      type: String,
      default: "",
    },
  },
  computed: {
    formattedProjectInfoText() {
      return this.projectInfoText.replace(/\n/g, "<br>");
    },
    formattedProjectInfoTextAbout() {
      return this.projectInfoTextAbout.replace(/\n/g, "<br>");
    },
  },
};
</script>

<style scoped>
.project-intro-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10vh 0;
  overflow-x: hidden;
}

.project-intro-content-img-container {
  margin: 10px;
  width: 50%;
}

.project-intro-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.project-intro-title-container {
  height: 30%;
}

.project-intro-text-container {
  height: 70%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: end;
}

.project-intro-img-container-desktop {
  display: block;
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-intro-img-container-desktop img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.project-intro-text-about {
  font-size: 18px;
  opacity: 0.9;
  font-style: italic;
  font-weight: 300;
  padding-top: 2vh;
}

h1 {
  padding: 0;
  width: 80%;
}

h2 {
  font-size: 28px;
}

.project-intro-img-container-mobile,
.project-intro-star-border-container {
  display: none;
}

@media (max-width: 870px) {
  .project-intro-container {
    flex-direction: column;
    z-index: 0;
  }

  .project-intro-img-container-desktop {
    display: none;
  }
  .project-intro-star-border-container {
    display: block;
  }

  .project-intro-img-container-mobile {
    display: block;
    width: 80%;
    position: relative;
    z-index: -22;
    left: 40%;
    transform: scale(1.4);
    border-radius: 500px;
  }

  .project-intro-img-container-mobile img {
    width: 100%;
    border-radius: 222px;
  }

  .project-intro-star-border-container svg {
    position: absolute;

    scale: 1.02;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 0;
    animation: starBorderRotate 20s linear infinite;
  }

  @keyframes starBorderRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .project-intro-text-container {
    padding-top: 4.5vh;
  }

  .project-intro-text-about {
    font-size: 12px;
  }

  h2 {
    font-size: 13.5px;
  }
}
</style>
