<template>
  <div id="header"></div>
  <div class="landing-header-container">
    <div class="header-content-container">
      <div class="header-text-container">
        <h1>Alyssa Tomala</h1>
        <h2 class="desktop-title">Interactive Media Designer</h2>
      </div>
      <div class="header-navigation">
        <p class="header-links-p" @click="navigateToHash('accordionProjects')">
          Projets
        </p>
        <router-link to="/about"><p>À propos</p></router-link>
        <p @click="navigateToHash('contact')">Contact</p>
      </div>
    </div>
  </div>
  <div class="header-background-container">
    <div class="header-background"></div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";

export default {
  mounted() {
    this.animateText();
  },
  methods: {
    animateText() {
      gsap.registerPlugin(SplitText);

      let split = new SplitText(".desktop-title", { type: "chars" });

      gsap.from(split.chars, {
        duration: 0.8,
        opacity: 0,
        y: 20,
        ease: "power2.out",
        stagger: 0.05,
      });
    },
    navigateToHash(id) {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Ajoutez un délai pour laisser le navigateur traiter l'événement
    },
  },
};
</script>

<style scoped>
.landing-header-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: left;
  position: relative;
}

.header-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.header-text-container {
  padding-top: 7%;
  width: 70%;
}

.header-navigation {
  padding-top: 9%;
  display: flex;
  flex-direction: column;
}

.header-navigation p {
  font-size: 30px;
  text-align: end;
  margin-bottom: 2vh;
  cursor: pointer; /* Ajoutez cette ligne pour changer le curseur en pointeur */
}

.header-background {
  background-image: url("../assets/img/img-header-tree.webp");
  background-size: 70%;
  background-position: left top;
  background-repeat: no-repeat;
  width: 100%;
  height: 120%;
}

.header-background-container {
  width: 110%;
  height: 150%;
  position: absolute;
  top: 0;
  left: -10vw;
  z-index: -10;
}

h1 {
  padding: 0;
}

@media (max-width: 870px) {
  h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 18px;
  }
  .landing-header-container {
    height: 60vh;
    padding-top: 8vh;
    margin: 0 8%;
  }

  .header-content-container {
    flex-direction: column;
    height: 50%;
  }

  .header-navigation p {
    font-size: 18px;
    margin-bottom: 1vh;
    text-align: left;
  }

  .header-background {
    height: 25%;
    top: 12.5%;
    background-size: 150%;
    position: relative;
    background-position: left center;
  }

  .header-text-container {
    width: 90%;
  }
}
</style>
