<template>
  <div class="showcase-container">
    <div class="showcase-scroll-container">
      <div class="showcase-introduction-container">
        <div class="showcase-text showcase-text-zero">
          <h1 class="showcase-introduction-title">Sélection de projets</h1>
          <div class="showcase-introduction-text">
            <p>
              Ceux-ci représentent mon univers, ma passion et mettent en lumière
              la formation que j’aie suivie.
            </p>
          </div>
        </div>
        <div class="showcase-text showcase-text-one">
          <div class="showcase-project-title">
            <h1>Echoes of Auda</h1>
            <h5>Frontend Development | UI Design | Illustration</h5>
          </div>
          <div>
            <p>
              C'est un site de promotion immersif pour le DLC du jeu
              plate-forme/aventure «Echoes of Auda».
            </p>
          </div>
        </div>
        <div class="showcase-text showcase-text-two">
          <div>
            <h1>Eikonica</h1>
            <h5>Frontend Development | UI Design | Illustration</h5>
          </div>

          <div>
            <p>
              Eikonica est un festival d'art numérique fictif qui se déroule à
              eikon.
            </p>
          </div>
        </div>
        <div class="showcase-text showcase-text-three">
          <div class="showcase-project-title">
            <h1>BotaBota</h1>
            <h5>UI Design | Graphisme</h5>
          </div>
          <div>
            <p>
              Botabota représente le jardin botanique de la ville de Fribourg.
            </p>
          </div>
        </div>

        <div class="showcase-projects-container">
          <router-link to="/auda">
            <div class="showcase-project-container one">
              <showcaseButton />
            </div>
          </router-link>
          <router-link to="/eikonica">
            <div class="showcase-project-container two"><showcaseButton /></div>
          </router-link>
          <router-link to="/botabota">
            <div class="showcase-project-container three">
              <showcaseButton />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import showcaseButton from "@/composants/buttonShowcaseComponent.vue";
export default {
  components: {
    showcaseButton,
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.headerUpAnimation();
    this.showcaseProjectSlider();
    this.showcaseTextSlider();
  },
  methods: {
    headerUpAnimation() {
      gsap.to(".header-background", {
        scrollTrigger: {
          trigger: ".showcase-introduction-container",
          start: "top bottom",
          end: "35% center",
          scrub: true,
        },
        y: "5vh",
      });
    },

    showcaseProjectSlider() {
      const elements = [".one", ".two", ".three"];
      elements.forEach((element, index) => {
        gsap.to(element, {
          scrollTrigger: {
            trigger: ".showcase-scroll-container",
            start: `${index * 0.1}% top`,
            end: "center top",
            scrub: true,
            pin: true,
          },
          x: "-100%",
        });
      });
    },

    showcaseTextSlider() {
      gsap.to(".showcase-text-zero", {
        scrollTrigger: {
          trigger: ".showcase-container",
          start: "top bottom",
          end: "top center",
          scrub: true,
        },
        opacity: 1,
      });
      gsap.to(".showcase-text-one", {
        scrollTrigger: {
          trigger: ".one",
          start: "top top",
          end: "center 30%",
          scrub: true,
        },
        opacity: 1,
      });
      gsap.to(".showcase-text-two", {
        scrollTrigger: {
          trigger: ".two",
          start: "center top",
          end: "bottom 30%",
          scrub: true,
        },
        opacity: 1,
      });
      gsap.to(".showcase-text-three", {
        scrollTrigger: {
          trigger: ".three",
          start: "bottom top",
          end: "150% 30%",
          scrub: true,
        },
        opacity: 1,
      });
    },
  },
};
</script>

<style scoped>
.showcase-scroll-container {
  width: 110%;
  height: 100vh;
  padding: 8% 0;
}

.showcase-project-container {
  height: 100vh;
  position: absolute;
  top: 0;
  right: -50vw;
  background-size: 122%;
  background-position: left bottom;
  background-repeat: no-repeat;
  border-radius: 40px 0 0 40px;
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.02);
  animation: zoomOutImg 0.5s forwards;
}
.one {
  width: 50vw;

  background-image: url("../assets/img/01-img-project-auda.webp");
}

.two {
  width: 50vw;
  background-image: url("../assets/img/02-project-eikonica.webp");
}
.three {
  width: 50.1vw;
  background-image: url("../assets/img/03-project-botabota.webp");
}

.showcase-project-container:hover {
  cursor: pointer;
  animation: zoomInImg 0.5s forwards;
}

.showcase-text {
  position: absolute;
  background-color: #fefdf3;
  padding: 10px;
  opacity: 0;
  width: 40%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.showcase-text-zero {
  background-color: #fefdf300;
}

h1 {
  padding: 0;
}

@keyframes zoomInImg {
  0% {
    background-size: 120%;
  }
  100% {
    background-size: 122%;
  }
}

@keyframes zoomOutImg {
  0% {
    background-size: 122%;
  }
  100% {
    background-size: 120%;
  }
}

@media (max-width: 870px) {
}
</style>
