<script>
import uiNav from "@/composants/projectNavComponent.vue";
import uiPageFooter from "@/composants/footerComponent.vue";
import uiIntro from "@/composants/projectIntroComponent.vue";
import uiIntroImg from "@/assets/img/img-project-leamo.webp";

import uiOneImg from "@/composants/projectOneImgComponent.vue";
import uiOneImgWebsite from "@/assets/img/img-project-leamo-04.webp";

import uiAccordion from "@/composants/accordionComponent.vue";

export default {
  components: {
    uiNav,
    uiPageFooter,
    uiIntro,
    uiOneImg,
    uiAccordion,
  },

  data() {
    return {
      uiIntroImg: uiIntroImg,
      uiOneImgWebsite: uiOneImgWebsite,
    };
  },
};
</script>
<template>
  <uiNav />
  <uiIntro
    projectInfoH1="Leamo"
    projectInfoH2="UI design | Frontend Développement"
    projectInfoText="Leamo est une marque de boissons pour jeunes. Dans le cadre de ce projet fictif, j'ai conçu la maquette de la page d’accueil du site internet puis je l'ai codé."
    projectInfoTextAbout="Ce projet a été réalisé lors de ma 2ème année d'apprentissage à eikon."
    :projectIntroImg="uiIntroImg"
  />

  <uiOneImg
    projectOneImgH3="Maquette et site web"
    :projectOneImg="uiOneImgWebsite"
    projectOneBoxShadow="0px 4px 15px 3px  rgba(0, 0, 0, 0.2)"
    buttonLink="https://alyssa-tomala.at-eikon.ch/leamo/"
    buttonText="Visiter le site web"
  />

  <uiAccordion />
  <uiPageFooter />
</template>

<script></script>

<style scoped>
@media (max-width: 870px) {
}
</style>
