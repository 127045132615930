<script>
import aboutNav from "@/composants/projectNavComponent.vue";

import aboutPageFooter from "@/composants/footerComponent.vue";
import aboutIntro from "@/composants/projectIntroComponent.vue";
import aboutIntroImg from "@/assets/img/img-about-portrait.webp";

import aboutSkills from "@/composants/projectThreeImgComponent.vue";
import aboutSkillsImgFirst from "@/assets/img/img-skills-01.webp";
import aboutSkillsImgSecond from "@/assets/img/img-skills-02.webp";
import aboutSkillsImgThird from "@/assets/img/img-skills-03.webp";

import aboutAccordion from "@/composants/accordionComponent.vue";

export default {
  components: {
    aboutNav,
    aboutPageFooter,
    aboutIntro,
    aboutSkills,
    aboutAccordion,
  },

  data() {
    return {
      aboutIntroImg: aboutIntroImg,
      aboutSkillsImgFirst: aboutSkillsImgFirst,
      aboutSkillsImgSecond: aboutSkillsImgSecond,
      aboutSkillsImgThird: aboutSkillsImgThird,
    };
  },
};
</script>
<template>
  <aboutNav />
  <aboutIntro
    projectInfoH1="À propos"
    projectInfoH2=""
    projectInfoText="Bonjour !
    Je m'appelle Alyssa Tomala et je suis apprentie interactive media designer à eikon, où je me spécialise dans le développement Frontend et l'UI. En parallèle de mon apprentissage, je pratique également l'illustration. Je suis passionnée par les jeux vidéo et mon futur métier. 
    Ce site est une vitrine de mon univers et de mes créations, que j'ai réalisées pendant ma scolarité ou durant mon temps libre.
"
    projectInfoTextAbout=""
    :projectIntroImg="aboutIntroImg"
  />
  <div id="aboutSkills"></div>
  <aboutSkills
    skillsH1="Mes compétences"
    :skillsImgFirst="aboutSkillsImgFirst"
    :skillsImgSecond="aboutSkillsImgSecond"
    :skillsImgThird="aboutSkillsImgThird"
  />
  <aboutAccordion accordionTitle="Tous mes projets" />
  <aboutPageFooter />
</template>

<script></script>

<style scoped>
@media (max-width: 870px) {
}
</style>
