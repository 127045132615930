<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #fefdf3;
  scroll-behavior: smooth;
  margin: 0 8%;
}

body {
  overflow-x: hidden;
}

h1,
h3,
h4 {
  font-family: "Satoshi";
  font-weight: 900;
  color: #191919;
  text-transform: uppercase;
}

h1 {
  font-size: 75px;
  line-height: 83px;
  padding-top: 4vh;
}

h2,
h5 {
  font-size: 40px;
  font-family: "Fafo";
  font-weight: 400;
  color: #191919;
}

h3 {
  font-size: 40px;
  padding: 60px 0 32px 0;
}

h5 {
  font-size: 24px;
  padding-left: 2px;
}

p {
  font-family: "Satoshi";
  font-weight: 400;
  color: #191919;
  font-size: 24px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 1;
}

hr {
  margin: 0;
  height: 1px;
  display: block;
  border-width: 0;
}

@media (max-width: 1570px) {
  h1 {
    font-size: 60px;
    line-height: 63px;
  }
}

@media (max-width: 870px) {
  html {
    margin: 0 4%;
  }
  h1 {
    font-size: 30px;
    line-height: 33px;
    padding-bottom: 30px;
  }

  h3 {
    font-size: 22px;
    padding: 2vh 2px 2.5vh;
  }

  h2,
  h5,
  p {
    font-size: 14px;
  }

  a {
    font-size: 16px;
  }
}
</style>

<style scoped>
@font-face {
  font-family: "Fafo";
  font-weight: 400;
  font-style: normal;
  src: url("../src/assets/fonts/Fafo-Sans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 300;
  font-style: normal;
  src: url("@/assets/fonts/Satoshi-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 500;
  font-style: normal;
  src: url("@/assets/fonts/Satoshi-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 900;
  font-style: normal;
  src: url("@/assets/fonts/Satoshi-Black.woff2") format("woff2");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 700;
  font-style: normal;
  src: url("@/assets/fonts/Satoshi-Bold.woff2") format("woff2");
}
</style>

<template>
  <router-view :key="$route.fullPath"></router-view>
</template>
