<script>
import graphicNav from "@/composants/projectNavComponent.vue";

import graphicPageFooter from "@/composants/footerComponent.vue";
import graphicIntro from "@/composants/projectIntroComponent.vue";
import graphicIntroImg from "@/assets/img/img-project-gegy.webp";

import graphicOneImgSimpleMagazine from "@/composants/projectOneImgComponent.vue";
import graphicOneImgSimpleMagazineImg from "@/assets/img/img-project-gegy-magazine-01.webp";

import graphicOneImgFunMagazine from "@/composants/projectOneImgComponent.vue";
import graphicOneImgFunMagazineImg from "@/assets/img/img-project-gegy-magazine-02.webp";

import graphicAccordion from "@/composants/accordionComponent.vue";

export default {
  components: {
    graphicNav,
    graphicPageFooter,
    graphicIntro,
    graphicOneImgSimpleMagazine,
    graphicOneImgFunMagazine,
    graphicAccordion,
  },

  data() {
    return {
      graphicIntroImg: graphicIntroImg,
      graphicOneImgSimpleMagazineImg: graphicOneImgSimpleMagazineImg,
      graphicOneImgFunMagazineImg: graphicOneImgFunMagazineImg,
    };
  },
};
</script>
<template>
  <graphicNav />
  <graphicIntro
    projectInfoH1="GejyJIJI"
    projectInfoH2="Graphisme"
    projectInfoText="Mise en page de deux doubles-pages de magazine pour promouvoir un artiste en particulier.
    Mon projet vise à mettre en valeur le travail d’une artiste Chinoise, Gegyjiji. Celle-ci est spécialisée dans l’aquarelle."
    projectInfoTextAbout="Ce projet a été réalisé lors de ma 2ème année d'apprentissage à eikon."
    :projectIntroImg="graphicIntroImg"
  />

  <graphicOneImgSimpleMagazine
    projectOneImgH3="Mise en page “traditionnelle”"
    :projectOneImg="graphicOneImgSimpleMagazineImg"
    :showButton="false"
  />

  <graphicOneImgFunMagazine
    projectOneImgH3="Mise en page “créative”"
    :projectOneImg="graphicOneImgFunMagazineImg"
    :showButton="false"
  />

  <graphicAccordion />
  <graphicPageFooter />
</template>

<script></script>

<style scoped>
@media (max-width: 870px) {
}
</style>
