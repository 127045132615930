import { createRouter, createWebHistory } from "vue-router";
import landingPage from "@/vue/landingPage.vue";
import graphicGejy from "@/vue/graphicGejy.vue";
import uiEikonica from "@/vue/uiEikonica.vue";
import uiBota from "@/vue/uiBota.vue";
import webAuda from "@/vue/webAuda.vue";
import graphicIsometric from "@/vue/graphicIsometric.vue";
import uiLeamo from "@/vue/uiLeamo.vue";
import aboutPage from "@/vue/aboutPage.vue";

const routes = [
  { path: "/", component: landingPage, name: "landingPage" },
  { path: "/gejy", component: graphicGejy, name: "gejy" },
  { path: "/eikonica", component: uiEikonica, name: "eikonica" },
  { path: "/botabota", component: uiBota, name: "botabota" },
  { path: "/auda", component: webAuda, name: "auda" },
  { path: "/isometric", component: graphicIsometric, name: "isometric" },
  { path: "/leamo", component: uiLeamo, name: "leamo" },
  { path: "/about", component: aboutPage, name: "about" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
