import "@/assets/styles/main.css"; // Assurez-vous que ce chemin est correct

import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";

createApp(App).use(router).mount("#app");

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};
