<script>
import uiNav from "@/composants/projectNavComponent.vue";
import uiPageFooter from "@/composants/footerComponent.vue";

import uiIntro from "@/composants/projectIntroComponent.vue";
import uiIntroImg from "@/assets/img/img-project-botabota.webp";

import uiTwoImg from "@/composants/projectTwoImgComponent.vue";
import uiTwoImgRight from "@/assets/img/img-project-botabota-01.webp";
import uiTwoImgLeft from "@/assets/img/img-project-botabota-02.webp";

import uiThreeImg from "@/composants/projectThreeImgComponent.vue";
import uiThreeFirst from "@/assets/img/img-project-bota-merch-01.webp";
import uiThreeSecond from "@/assets/img/img-project-bota-merch-02.webp";
import uiThreeThird from "@/assets/img/img-project-bota-merch-03.webp";

import uiOneImg from "@/composants/projectOneImgComponent.vue";
import uiOneImgInstagram from "@/assets/img/img-project-botabota-instagram-03.webp";
import uiOneImgInstagramMobile from "@/assets/img/img-project-bota-instagram-mobile.webp";
import uiOneImgWebsite from "@/assets/img/img-project-botabota-04.webp";

import uiAccordion from "@/composants/accordionComponent.vue";
export default {
  components: {
    uiNav,
    uiPageFooter,
    uiIntro,
    uiThreeImg,
    uiTwoImg,
    uiOneImg,
    uiAccordion,
  },

  data() {
    return {
      uiIntroImg: uiIntroImg,
      uiTwoImgRight: uiTwoImgRight,
      uiTwoImgLeft: uiTwoImgLeft,
      uiOneImgInstagram: uiOneImgInstagram,
      uiOneImgInstagramMobile: uiOneImgInstagramMobile,
      uiOneImgWebsite: uiOneImgWebsite,
      uiThreeFirst: uiThreeFirst,
      uiThreeSecond: uiThreeSecond,
      uiThreeThird: uiThreeThird,
    };
  },
};
</script>
<template>
  <uiNav />
  <uiIntro
    projectInfoH1="BotaBota"
    projectInfoH2="UI design | Graphisme"
    projectInfoText="Botabota représente le jardin botanique de la ville de Fribourg. 
    Dans le cadre de ce projet fictif, j'ai conçu, avec une partenaire, la maquette de la page d’accueil du site internet ainsi que toute l’identité visuelle du jardin."
    projectInfoTextAbout="Ce projet a été fait dans le cadre de mon apprentissage à eikon en 3ième année."
    :projectIntroImg="uiIntroImg"
  />
  <uiTwoImg
    projectTwoImgH3="Logo"
    :projectTwoImgRight="uiTwoImgRight"
    :projectTwoImgLeft="uiTwoImgLeft"
  />

  <uiThreeImg
    skillsH3="Merch"
    :skillsImgFirst="uiThreeFirst"
    :skillsImgSecond="uiThreeSecond"
    :skillsImgThird="uiThreeThird"
    :showText="false"
  />

  <uiOneImg
    class="ui-instagram-desktop"
    projectOneImgH3="Page instagram"
    :projectOneImg="uiOneImgInstagram"
    projectOneBoxShadow="none"
    :showButton="false"
  />

  <uiOneImg
    class="ui-instagram-mobile"
    projectOneImgH3="Page instagram"
    :projectOneImg="uiOneImgInstagramMobile"
    projectOneBoxShadow="none"
    :showButton="false"
  />
  <uiOneImg
    projectOneImgH3="Maquette du site web"
    :projectOneImg="uiOneImgWebsite"
    buttonLink="false"
    :showButton="false"
  />
  <uiAccordion />

  <uiPageFooter />
</template>

<script></script>

<style scoped>
.ui-instagram-mobile {
  display: none;
}
.ui-instagram-desktop {
  display: block;
}
@media (max-width: 870px) {
  .ui-instagram-desktop {
    display: none;
  }
  .ui-instagram-mobile {
    display: block;
  }
}
</style>
