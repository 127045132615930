<script>
import webNav from "@/composants/projectNavComponent.vue";
import webPageFooter from "@/composants/footerComponent.vue";
import webIntro from "@/composants/projectIntroComponent.vue";
import webIntroImg from "@/assets/img/img-project-auda.webp";

import webTwoImg from "@/composants/projectTwoImgComponent.vue";
import webTwoImgRight from "@/assets/img/img-project-auda-02.webp";
import webTwoImgLeft from "@/assets/img/img-project-auda-01.webp";

import webOneImg from "@/composants/projectOneImgComponent.vue";
import webOneImgInstagram from "@/assets/img/img-project-auda-instagram-03.webp";
import webOneImgInstagramMobile from "@/assets/img/img-project-auda-instagram-mobile.webp";
import webOneImgWebsite from "@/assets/img/img-project-auda-04.webp";

import webAccordion from "@/composants/accordionComponent.vue";

export default {
  components: {
    webNav,
    webPageFooter,
    webIntro,
    webTwoImg,
    webOneImg,
    webAccordion,
  },

  data() {
    return {
      webIntroImg: webIntroImg,
      webTwoImgRight: webTwoImgRight,
      webTwoImgLeft: webTwoImgLeft,
      webOneImgInstagram: webOneImgInstagram,
      webOneImgInstagramMobile: webOneImgInstagramMobile,
      webOneImgWebsite: webOneImgWebsite,
    };
  },
};
</script>
<template>
  <webNav />
  <webIntro
    projectInfoH1="Echoes of Auda"
    projectInfoH2="UI design | Frontend Développement | Illustration"
    projectInfoText="C'est un site de promotion immersif pour le DLC du jeu plate-forme/aventure «Echoes of Auda». Les utilisateurs découvrent, à travers une expérience de storytelling immersive, le DLC « Auda : Origins of the monumental realm ».
    J'ai conçu l'univers du jeu, les illustrations, le character design des deux personnages, la maquette du site internet puis je l'ai codé."
    projectInfoTextAbout="Ce projet de fin d'année a été réalisé dans le cadre de mon apprentissage à eikon en 3ème année.
    Tout le projet est fictif et n'a été réalisé que dans le cadre de ma scolarité."
    :projectIntroImg="webIntroImg"
  />
  <webTwoImg
    projectTwoImgH3="Auda et Orbon"
    :projectTwoImgRight="webTwoImgRight"
    :projectTwoImgLeft="webTwoImgLeft"
  />

  <webOneImg
    class="web-instagram-mobile"
    projectOneImgH3="Page instagram"
    :projectOneImg="webOneImgInstagramMobile"
    projectOneBoxShadow="none"
    :showButton="false"
  />

  <div class="web-one-video-instagram">
    <h3>Page instagram</h3>
    <video
      src="@/assets/img/video-auda-instagram.mp4"
      loop
      autoplay
      muted
    ></video>
  </div>

  <webOneImg
    projectOneImgH3="Site web"
    :projectOneImg="webOneImgWebsite"
    projectOneBoxShadow="none
    "
    buttonLink="https://alyssa-tomala.at-eikon.ch/auda/"
    buttonText="Visiter le site web"
  />
  <webAccordion />
  <webPageFooter />
</template>

<script></script>

<style scoped>
.web-instagram-mobile {
  display: none;
}

.web-one-video-instagram {
  width: 100%;
  text-align: center;
}
.web-one-video-instagram video {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media (max-width: 870px) {
  .web-one-video-instagram {
    display: none;
  }
  .web-instagram-mobile {
    display: block;
  }
}
</style>
