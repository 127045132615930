<script>
import uiNav from "@/composants/projectNavComponent.vue";
import uiPageFooter from "@/composants/footerComponent.vue";
import uiIntro from "@/composants/projectIntroComponent.vue";
import uiIntroImg from "@/assets/img/img-project-eikonica.webp";

import uiThreeImg from "@/composants/projectThreeImgComponent.vue";
import uiThreeFirst from "@/assets/img/img-project-eikonica-iko-01.webp";
import uiThreeSecond from "@/assets/img/img-project-eikonica-iko-02.webp";
import uiThreeThird from "@/assets/img/img-project-eikonica-iko-03.webp";

import uiOneImg from "@/composants/projectOneImgComponent.vue";
import uiOneImgWebsite from "@/assets/img/img-project-eikonica-04.webp";

import uiAccordion from "@/composants/accordionComponent.vue";

export default {
  components: {
    uiNav,
    uiPageFooter,
    uiIntro,
    uiOneImg,
    uiAccordion,
    uiThreeImg,
  },

  data() {
    return {
      uiIntroImg: uiIntroImg,
      uiOneImgWebsite: uiOneImgWebsite,
      uiThreeFirst: uiThreeFirst,
      uiThreeSecond: uiThreeSecond,
      uiThreeThird: uiThreeThird,
    };
  },
};
</script>
<template>
  <uiNav />
  <uiIntro
    projectInfoH1="Eikonica"
    projectInfoH2="UI design | Frontend Développement | Illustration"
    projectInfoText="Eikonica est un festival d'art numérique fictif qui se déroule à eikon.
    Dans le cadre de cet événement, j'ai conçu et codé la page d'accueil du site internet, en plus de créer une mascotte exclusive pour le festival."
    projectInfoTextAbout="Ce projet a été réalisé lors de ma 2ème année d'apprentissage à eikon."
    :projectIntroImg="uiIntroImg"
  />
  <div class="eikonica-iko-container">
    <uiThreeImg
      skillsH3="Iko"
      skillsTitleColor="#fefdf3"
      :skillsImgFirst="uiThreeFirst"
      :skillsImgSecond="uiThreeSecond"
      :skillsImgThird="uiThreeThird"
      :showText="false"
    />
    <p>
      Iko tire son nom du mélange de “eikon” et de “écho” car celui-ci fait le
      lien entre tous les arts numériques par sa forme et sa manière d’interagir
      avec les informations.
      <br />
      Il se veut dynamique, informatisé et déclinable pour tous les types de
      médias.
    </p>
  </div>

  <uiOneImg
    projectOneImgH3="Maquette et site web"
    :projectOneImg="uiOneImgWebsite"
    buttonLink="https://alyssa-tomala.at-eikon.ch/eikonica/"
    buttonText="Visiter le site web"
  />
  <uiAccordion />
  <uiPageFooter />
</template>

<script></script>

<style scoped>
.eikonica-iko-container {
  background-color: #191919;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.2);
  padding: 2vh;
}

.eikonica-iko-container p {
  padding: 0 5vw;
  font-size: 1.2em;
  line-height: 1.5em;
  margin-bottom: 4vh;
  color: #fefdf3;
}
@media (max-width: 870px) {
}
</style>
